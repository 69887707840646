.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.font-link {
  font-family: 'Hanalei Fill', cursive;
}

.body {
  font-family: 'Cairo', sans-serif !important;
}


.Text-Input-Style {
  margin-top: 3vh;
}

.Text-Label-Style {
  color: white;
  display: block;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.Contract-Button {
  background-color: #45aa42;
  padding: 15px 25px;
  color: white;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 25px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  font-weight: bold;
  margin-left: 5vh;
  display: block;
  font-size: 18px;
  width: 50vh
}

.Generate-PDF-Button {
  background-color: #45aa42;
  padding: 10px 25px;
  color: white;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  font-weight: bold;
  margin-top: 10vh;
}

.Generate-Cashflow-PDF-Button {
  background-color: #45aa42;
  padding: 15px 35px;
  color: white;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 10px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  font-weight: bold;
  margin-top: 3vh;
}

.edit-cashflow-inputs-button {
  padding: 2px 5px;
  color: white;
  border-radius: 5px;
  border: none;
  outline: 0;
  text-transform: uppercase;
  margin: 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  margin-top: 2vh;
}

.add-row-cashflow-button {
  background-color: green;
  padding: 5px 15px;
  color: white;
  border-radius: 5px;
  outline: 0;
  text-transform: uppercase;
  margin: 5px 0px;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
  font-weight: bold;
  margin-top: 3vh;
}

.delete-cash-flow-input-row{
  background-color: red;
  padding: 0px 2px;
  margin: 0px 5px 0px 0px;
  border: none;
  color: white;
  border-radius: 5px;
  outline: 0;
  cursor: pointer;
  box-shadow: 0px 2px 2px lightgray;
  transition: ease background-color 250ms;
}

.Text-Input-Number-Style{
  width: 10vh;
  margin-left: 2vh
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

